export const lightTheme = {
    body: '#f5f5f5',
    text: '#18498F',
    text2: '#204285',
    text3: '#388e3c',
    text4: '#931f44',
    text5: '#000',
    text6: '#918f8f',
    Button : '#00B369',
    Button2 : '#8b8b8b',
    Button3 : '#01B16B',
    toggleBorder: '#e5e5e5',
    hoverSelect : '#d6e7f7',
    background: '#fbfbfd',
    BoxShadow : '0px 4px 9px 1px rgba(51, 51, 51, 0.062)',
    div : 'rgb(249, 250, 252)',
    div2 : 'rgb(255, 255, 255)',
    div3: '#F2F2F2',
    div4: '#FAFCFD',
    div5: '#F1F3F6',
    div6: '#F5E5D9',
    row1 : '#EFF1F2',
    scrollbartrack : '#e7e7e7',
    scrollbarthumb : '#dadada',
}
export const darkTheme = {
    body: '#222222',
    text: '#c6c7ca',
    text2: '#9a9b9d',
    text3: '#388e3c',
    text4: '#931f44',
    text5: '#fff',
    Button : '#00B369',
    Button2 : '#8b8b8b',
    Button3 : '#01B16B',
    toggleBorder: '#2a2a2a',
    background: '#161616',
    BoxShadow : '0 7px 10px #0000003f',
    hoverSelect : '#212121',
    div : '#161616',
    div2 : '#212121',
    div3: '#121111',
    div4: '#1a1a1a',
    div5: '#1a1a1a',
    div6: '#2f2225',
    row1 : '#171717',
    scrollbartrack : '#121212',
    scrollbarthumb : '#323232',
}