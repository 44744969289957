import React from "react";
//import Theme components
import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./Theme/globalStyles";
import { lightTheme, darkTheme } from "./Theme/Themes"

function App() {
    //Dark mode Toggle
    const [theme, setTheme] = React.useState('dark');
    const themeToggler = () => {
      theme === 'light' ? setTheme('dark') : setTheme('light')
    }
  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles/>
    <div className="App">
      <header className="Container--global--row--between pad40 Height--15--vh">
        <h1 className="fw800 ThemeText5 fs25">گیک ناو | GeekNow 👋🏻</h1>
        <nav className="Container--global--row--evenly Width--auto">
          <ul className="Container--global--row--evenly">
            <li className="pad20 fs16 fw400 ThemeText5 cursorPointer">اپیزودها</li>
            <li className="pad20  mar-r40 fs16 fw400 ThemeText5 cursorPointer">مطالب</li>
            <li className="pad20  mar-r40 fs16 fw400 ThemeText5 cursorPointer">حمایت</li>
          </ul>
        </nav>
        <div className="Container--global--row--evenly Width--auto h30 pad20 Border--radius--global Button--primery">
          <small className="fs15 Text--light mar-l20">ارسال ایده</small>
          <i class="fi fi-rr-bulb"></i>
        </div>
      </header>
      <section className="Container--global--column--start Width--100 Height--85--vh">
        <div className="Container--global--column--start Width--1000--px Height--100 Div--melo--gray Border--radius--global ">
          <div className="Container--global--row--between Width--100 h50 mar-b20 pad40 ThemeBorderBottom">
            <i class="fi fi-rr-moon i" onClick={() => {themeToggler()}}></i>
            <div className="PanelInput--global ThemeLayer5 pad10">
              <i class="fi fi-rr-search i"></i>
              <input type="text" className="Input--global" placeholder="دنبال مطلب خاصی میگردی؟" />
              <div className="Container--global--row Button--primery pad6 Border--radius--global cursorPointer">
                <i class="fi fi-rr-arrow-left"></i>
              </div>
            </div>
            <i class="fi fi-rr-rotate-right i"></i>
          </div>
          <main className="Container--global--column--start Width--100 Height--100">
            <div className="Container--global--row--evenly h200 Width--90 ThemeShadow Border--radius--global Overflow--hidden--global ThemeLayer3">
              <div className="Container--global--row--evenly h200 w200">
                <img src="https://images.pexels.com/photos/9633678/pexels-photo-9633678.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" alt="" />
              </div>
              <div className="Container--global--column--evenly h200 pad20">
                <div className="Container--global--column--evenly h200" style={{alignItems : 'flex-start'}}>
                  <h2 className="ThemeText5">سیستم مدیریت محتوا چیست؟ (CMS)</h2>
                  <h3 className="ThemeText5" style={{opacity : '.7'}}>اول از همه باید بدونیم که CMS مخفف چیه؟ این واژه مخفف کلمات ‌‌Content Management System هستش </h3>
                </div>
                <div className="Container--global--row--between h10 Width--100">
                  <div className="Button--primery Border--radius--global pad-lr10">
                    <span className="Text--light">نویسنده : سید محمد میرفاطمی</span>
                  </div>
                  <div>
                    <ul className="Container--global--row--evenly">
                      <li className="Width--auto pad-lr10 Border--radius--global ThemeLayer5 mar5 ThemeText5"># tag</li>
                      <li className="Width--auto pad-lr10 Border--radius--global ThemeLayer5 mar5 ThemeText5"># tag</li>
                      <li className="Width--auto pad-lr10 Border--radius--global ThemeLayer5 mar5 ThemeText5"># tag</li>
                    </ul>
                  </div>
                </div>
              </div>
              </div>
          </main>
        </div>
      </section>
      <footer className="Container--global--row--between pad20 Height--10--vh mar-t40 ThemeLayer2">
        <span className="ThemeText5">کپی رایت و از این جور حرفا ©</span>
        <ul className="Container--global--row">
          <li className="Container--global--row w30 h30 mar10" style={{opacity : '.4'}}><img src="https://cdn-icons-png.flaticon.com/512/733/733609.png" alt="" /></li>
          <li className="Container--global--row w30 h30 mar10" style={{opacity : '.4'}}><img src="https://cdn-icons-png.flaticon.com/512/1384/1384031.png" alt="" /></li>
          <li className="Container--global--row w30 h30 mar10" style={{opacity : '.4'}}><img src="https://cdn-icons-png.flaticon.com/512/733/733635.png" alt="" /></li>
          <li className="Container--global--row w30 h30 mar10" style={{opacity : '.4'}}><img src="https://cdn-icons-png.flaticon.com/512/1384/1384028.png" alt="" /></li>
          <li className="Container--global--row w30 h30 mar10" style={{opacity : '.4'}}><img src="https://cdn-icons-png.flaticon.com/512/25/25631.png" alt="" /></li>
        </ul>
      </footer>
    </div>
    </ThemeProvider>
  );
}

export default App;
