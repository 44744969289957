import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './Styles/default.css';
import App from './App';

Sentry.init({
    dsn: "https://a4ae145501f2441ea8634e87a98fcd0a@o1035067.ingest.sentry.io/6006796",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1,
});

ReactDOM.render( 
    <React.StrictMode >
    <App />
    </React.StrictMode>,
    document.getElementById('root')
);