import { createGlobalStyle} from "styled-components"
export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    font-family: Tahoma, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
  }
  .ThemeLayer {
    background: ${({ theme }) => theme.div3};
  }
  .ThemeLayer2 {

    background : ${({ theme }) => theme.div4};

  }

  .ThemeLayer3 {

    background : ${({ theme }) => theme.div2} !important;

  }

  .ThemeLayer4 {

    background : ${({ theme }) => theme.div5} !important;

  }

  .ThemeLayer2 > ul > li > span{


    color : ${({ theme }) => theme.text};

  }
  .ThemeBorder {

    border : 2px ${({ theme }) => theme.toggleBorder} solid;

  }
  .ThemeLayer5 {

    background : ${({ theme }) => theme.toggleBorder};

  }

  .ThemeLayer6 {

    background : ${({ theme }) => theme.div6};

  }
  .ThemeBorderBottom {

    border-bottom : 2px ${({ theme }) => theme.toggleBorder} solid;

  }

  .ThemeBorderRight {

    border-right : 2px ${({ theme }) => theme.toggleBorder} solid;

  }

  .ThemeBorderLeft {

    border-left : 2px ${({ theme }) => theme.toggleBorder} solid;

  }

  .MuiSvgIcon-root {

    fill : ${({ theme }) => theme.text2};

  }
  .ThemeText1 {

    color : ${({ theme }) => theme.text} !important;
    fill : ${({ theme }) => theme.text} !important;

  }
  .ThemeText2 {

    color : ${({ theme }) => theme.text2} !important;
    fill : ${({ theme }) => theme.text2} !important;

  }
  .ThemeText3 {

    color : ${({ theme }) => theme.text3} !important;
    fill : ${({ theme }) => theme.text3} !important;

  }
  .ThemeText4 {

    color : ${({ theme }) => theme.text4} !important;
    fill : ${({ theme }) => theme.text4} !important;

  }
  .ThemeText5 {

    color : ${({ theme }) => theme.text5} !important;
    fill : ${({ theme }) => theme.text5} !important;

  }
  a , p , span , h3 ,h1 ,h4 , h2 {

    color : ${({ theme }) => theme.text};

  }
  th {

    background : ${({ theme }) => theme.row1};
    color : ${({ theme }) => theme.text5};

  }

  tr:nth-child(even) {

    background : ${({ theme }) => theme.row1} !important;

  }

  input {

    background: ${({ theme }) => theme.div3};
    color : ${({ theme }) => theme.text2};

  }
  textarea {

    background: ${({ theme }) => theme.body};
    color : ${({ theme }) => theme.text2};

  }
  .SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option > .side-navigation-panel-select-option-wrap > .side-navigation-panel-select-option-text  {

    color : ${({ theme }) => theme.text};

  }
  .side-navigation-panel-select-option-icon-chevron-down {

    color : ${({ theme }) => theme.text};
    stroke : ${({ theme }) => theme.text};

  }
  .side-navigation-panel-select-option-icon-chevron-up {

    color : ${({ theme }) => theme.text};
    stroke : ${({ theme }) => theme.text};

  }
  .ThemeShadow {

    box-shadow : ${({ theme }) => theme.BoxShadow};

  }
  .ThemeButton {

    background: ${({ theme }) => theme.Button};

  }
  .ThemeButton2 {

    background: ${({ theme }) => theme.Button2};

  }
  .ThemeButton3 {

    background: ${({ theme }) => theme.Button3};

  }

  
  .side-navigation-panel-select-inner-option-text {

    color : ${({ theme }) => theme.text2} !important;

  }
  .side-navigation-panel-select-inner-option-selected {

    background : ${({ theme }) => theme.div2} !important;

  }

  .side-navigation-panel-select-inner-option-selected:hover {

    background : ${({ theme }) => theme.div2} !important;

  }
  tr  {

    border-bottom: 1px solid ${({ theme }) => theme.div2} !important;

  }
  .PriceText {

    color : ${({ theme }) => theme.text6} !important;

  }
  .MinMax {

    color : ${({ theme }) => theme.text6} !important;

  }
  .SidebarWrapper > .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-wrap > .side-navigation-panel-select-option-selected {

    border: unset;
    background : ${({ theme }) => theme.hoverSelect} !important; !important;
  
  }
  .side-navigation-panel > .side-navigation-panel-select > .side-navigation-panel-select-inner > .side-navigation-panel-select-inner-wrap > .side-navigation-panel-select-inner-option-selected {

    background : ${({ theme }) => theme.hoverSelect} !important; !important;
  
  }
  
/* Track */
::-webkit-scrollbar-track {
  background: ${({ theme }) => theme.scrollbartrack};
}
::scrollbar-track {
  background: ${({ theme }) => theme.scrollbartrack};
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: ${({ theme }) => theme.scrollbarthumb};
}
::-scrollbar-thumb {
  background: ${({ theme }) => theme.scrollbarthumb};
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a5a5a5;
}
::-scrollbar-thumb:hover {
  background: #a5a5a5;
}
.ThemeBorderRadius {

  border-radius : 10px;

}
.i {

  color: ${({ theme }) => theme.text5};

}
  `
